import React, { useEffect, useState } from "react";

import "./OptionsField.scss";

import maleImg from "../public/mann.png";
import femaleImg from "../public/kvinne.png";

import shoppingImg from "../public/shopping.svg";
import grontImg from "../public/gront.svg";
import lekeplassImg from "../public/lekeplass.svg";
import trikkImg from "../public/trikk.svg";
import boligImg from "../public/bolig.svg";

import sintImg from "../public/sint.svg";
import mindreSintImg from "../public/mindre_sint.svg";
import noytralImg from "../public/noytral.svg";
import godImg from "../public/god.svg";
import veldigGod from "../public/veldig_god.svg";

import arbeidImg from "../public/arbeid.svg";
import besokImg from "../public/besok.svg";
import innbyggerImg from "../public/innbygger.svg";

const genderItems = [
  { value: "Mann", img: maleImg },
  { value: "Kvinne", img: femaleImg },
];
export function GenderField(props) {
  return <OptionsField className="gender" {...props} items={genderItems} />;
}

const locationTypeItems = [
  { value: "Handel og kultur", img: shoppingImg },
  { value: "Grøntområde", img: grontImg },
  { value: "Sport, lek og fritid", img: lekeplassImg },
  { value: "Gate, veg og kollektiv", img: trikkImg },
  { value: "Boligområde", img: boligImg },
];
export function LocationTypeField(props) {
  return (
    <OptionsField
      className="location-type"
      {...props}
      items={locationTypeItems}
    />
  );
}

const ratingItems = [
  { value: 1, img: sintImg },
  { value: 2, img: mindreSintImg },
  { value: 3, img: noytralImg },
  { value: 4, img: godImg },
  { value: 5, img: veldigGod },
];
export function RatingField(props) {
  return (
    <OptionsField
      className="rating"
      {...props}
      items={ratingItems}
      hideLabelText
    />
  );
}

const affiliationItems = [
  { value: "Innbygger", img: innbyggerImg },
  { value: "Jobber her", img: arbeidImg },
  { value: "På besøk", img: besokImg },
];
export function AffiliationField(props) {
  return (
    <OptionsField className="affiliation" {...props} items={affiliationItems} />
  );
}

export default function OptionsField({
  className,
  name,
  value,
  onValueChange,
  required,
  onValidityChange,
  items,
  hideLabelText,
}) {
  const [valid, setValid] = useState(true);

  useEffect(() => {
    const invalidRequired = required && !value;

    const isValid = !invalidRequired;
    if (isValid !== valid) {
      setValid(isValid);
      onValidityChange && onValidityChange(isValid);
    }
  }, [value, valid, required, onValidityChange]);

  return (
    <div className={"options-field" + (className ? " " + className : "")}>
      {items.map((item) => {
        const isObject = typeof item === "object" && item !== null;
        const itemValue = isObject ? item.value : item;
        return (
          <label
            aria-label={hideLabelText ? itemValue : ""}
            className={"btn option " + (value === itemValue ? "selected" : "")}
            key={name + "_" + itemValue}
          >
            {isObject && item.img && <img src={item.img} alt={itemValue} />}
            <input
              type="radio"
              name={name}
              key={itemValue}
              checked={value === itemValue}
              onChange={(e) => e.target.checked && onValueChange(itemValue)}
            />
            <span>{!hideLabelText && itemValue}</span>
          </label>
        );
      })}
    </div>
  );
}
