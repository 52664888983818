import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import request from "superagent";
import Page from "./components/Page";
import GeometryField from "./components/GeometryField";
import PointGeometryPicker from "./components/PointGeometryPicker";
import firstImg from "./public/intro.png";
import takkImg from "./public/tipptopp.svg";
import OptionsField, {
  AffiliationField,
  GenderField,
  LocationTypeField,
  RatingField,
} from "./components/OptionsField";
import "./App.scss";
import TextAreaField from "./components/TextAreaField";
import StedTilgjengelighetField from "./components/StedTilgjengelighetField";
import Summary from "./components/Summary";
import Help from "./components/Help";
import getConfig from "./config";
const pages = [
  { id: "intro" },
  {
    id: "kjonn",
    options: ["Mann", "Kvinne"],
    title: "Hvilket kjønn identifiserer du deg som?",
    hasImage: true,
  },
  {
    id: "alder",
    title: "Hvor gammel er du?",
    options: ["10-19", "20-29", "30-65", "66-79", "80 og over"],
  },
  {
    id: "tilhorighet",
    options: ["Innbygger", "Jobber her", "På besøk"],
    title: "Hva er din tilhørighet til stedet?",
    hasImage: true,
  },
  { id: "geom_wkt", title: "Hvor i kartet er stedet?" },
  {
    id: "sted_type",
    options: [
      "Handel og kultur",
      "Grøntområde",
      "Sport, lek og fritid",
      "Gate, veg og kollektiv",
      "Boligområde",
    ],
    title: "Hvilket type sted vil du si noe om? ",
    hasImage: true,
  },
  {
    id: "sted_rangering",
    options: [1, 2, 3, 4, 5],
    title: "Hva synes du om stedet?",
    hasImage: true,
  },
  /*{
    id: "sted_fornoyd",
    title: "Er du fornøyd med stedet?",
    options: ["ja", "nei"],
  },*/
  { id: "sted_fornoyd_kommentar", title: "Hva liker du ved dette stedet?" },
  {
    id: "sted_misfornoyd_kommentar",
    title: "Hva burde vært bedre på dette stedet? ",
  },
  {
    id: "vanskelig_tilgjengelig",
    title:
      "Er det noe ved dette stedet som føles utrygt eller som gjør det vanskelig for deg å bruke stedet slik som du har lyst til?",
  },
  { id: "lagre", title: "Din besvarelse" },
  { id: "takk" },
];

const Form = ({ children }) => {
  const formRef = useRef();

  useEffect(() => {
    const input = formRef.current.querySelector("input");
    if (input) {
      input.focus();
      return;
    }
    const textarea = formRef.current.querySelector("textarea");
    if (textarea) {
      textarea.focus();
      return;
    }
  }, [formRef]);

  return <form ref={formRef}>{children}</form>;
};

const config = getConfig();

function App() {
  const [record, setRecord] = useState({});
  const [activePageIdx, setActivePageIdx] = useState(0);
  const [recordValid, setRecordValid] = useState({});
  const [saving, setSaving] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const nextPage = useCallback((overrideIdx) => {
    if (overrideIdx != null) {
      setActivePageIdx(overrideIdx);
      setRecord({});
      setRecordValid({});
      return;
    }
    setActivePageIdx((currentIdx) => currentIdx + 1);
  }, []);

  const previousPage = useCallback(() => {
    setActivePageIdx((currentIdx) => currentIdx - 1);
  }, []);

  const handleValueChanged = useCallback(
    (activePage, value) => {
      console.log("activePage", activePage, "value", value);
      setRecord({ ...record, [activePage.id || activePage]: value });
    },
    [record]
  );

  const handleValidityChanged = useCallback((activePage, valid) => {
    setRecordValid((recordValid) => ({
      ...recordValid,
      [activePage.id || activePage]: valid,
    }));
  }, []);

  const handleSave = useCallback(() => {
    setSaving(true);
    request
      .post(config.adaptiveUrl + "WebServices/client/DataView.asmx/Save")
      .send({
        theme_uuid: config.themeUuid,
        data: record,
      })
      .set("X-Adaptive-SRID", "3857")
      .then((res) => res.body)
      .then((res) => {
        setSaving(false);
        if (!res.d.success) {
          console.error("Could not save project", res.d);
          return;
        }
        nextPage();
      })
      .catch((e) => {
        setSaving(false);
        console.error("Could not save project", e);
      });
  }, [record, nextPage]);

  const activePage = useMemo(() => pages[activePageIdx], [activePageIdx]);

  console.log(
    activePage,
    activePageIdx,
    "record.sted_fornoyd",
    record.sted_fornoyd,
    recordValid
  );
  const {
    title,
    subtitle,
    content,
    isValid,
    theme,
    hideHeader,
    submitText,
    onSubmit,
  } = useMemo(() => {
    switch (activePage.id) {
      case "intro":
        return {
          content: (
            <div className="intro">
              <img src={firstImg} alt="" />
              <h1>Velkommen til stedsbruk</h1>
              <p>
                Her kan du gi tilbakemelding til politikere og planleggere, på
                hvordan du opplever ulike steder i kommunen.
              </p>
            </div>
          ),
          isValid: true,
          hideHeader: true,
          submitText: "Start",
        };
      case "geom_wkt":
        return {
          title: activePage.title,
          subtitle: "Bruk posisjon eller angi posisjon i kartet",
          content: (
            <GeometryField options={{}}>
              <PointGeometryPicker
                required
                value={record[activePage.id]}
                onValueChange={(value) => {
                  handleValueChanged(activePage, value);
                }}
                mapProjection={"3857"}
                onValidityChange={(isValid) => {
                  handleValidityChanged(activePage, isValid);
                }}
              />
            </GeometryField>
          ),
          theme: "map-page",
        };
      case "kjonn":
        return {
          title: activePage.title,
          content: (
            <Form key={activePage.id}>
              <GenderField
                name={activePage.id}
                value={record[activePage.id]}
                onValueChange={(value) => {
                  handleValueChanged(activePage, value);
                }}
                onValidityChange={(isValid) => {
                  handleValidityChanged(activePage, isValid);
                }}
                items={activePage.options}
                required
              />
            </Form>
          ),
        };
      case "sted_type":
        return {
          title: activePage.title,
          content: (
            <Form key={activePage.id}>
              <LocationTypeField
                name={activePage.id}
                value={record[activePage.id]}
                onValueChange={(value) => {
                  handleValueChanged(activePage, value);
                }}
                onValidityChange={(isValid) => {
                  handleValidityChanged(activePage, isValid);
                }}
                items={activePage.options}
                required
              />
            </Form>
          ),
        };
      case "tilhorighet":
        return {
          title: activePage.title,
          content: (
            <Form key={activePage.id}>
              <AffiliationField
                name={activePage.id}
                value={record[activePage.id]}
                onValueChange={(value) => {
                  handleValueChanged(activePage, value);
                }}
                onValidityChange={(isValid) => {
                  handleValidityChanged(activePage, isValid);
                }}
                items={activePage.options}
                required
              />
            </Form>
          ),
        };
      case "sted_rangering":
        return {
          title: activePage.title,
          content: (
            <Form key={activePage.id}>
              <RatingField
                name={activePage.id}
                value={record[activePage.id]}
                onValueChange={(value) => {
                  handleValueChanged(activePage, value);
                }}
                onValidityChange={(isValid) => {
                  handleValidityChanged(activePage, isValid);
                }}
                items={activePage.options}
                required
              />
            </Form>
          ),
        };

      /*case "alder":
        return {
          title: activePage.title,
          content: (
            <NumberField
              labelText={"Skriv din alder"}
              value={record.alder}
              onValueChange={(value) => {
                handleValueChanged(activePage, value);
              }}
              onValidityChange={(isValid) =>
                handleValidityChanged(activePage, isValid)
              }
              minValue={0}
              required
            />
          ),
          theme: "white",
        };*/
      case "sted_fornoyd":
      case "alder":
        return {
          title: activePage.title,
          content: (
            <Form key={activePage.id}>
              <OptionsField
                className={activePage.id}
                name={activePage.id}
                value={record[activePage.id]}
                onValueChange={(value) => {
                  handleValueChanged(activePage, value);
                }}
                onValidityChange={(isValid) => {
                  handleValidityChanged(activePage, isValid);
                }}
                items={activePage.options}
                required
              />
            </Form>
          ),
        };
      case "sted_fornoyd_kommentar":
      case "sted_misfornoyd_kommentar":
        return {
          title: activePage.title,
          content: (
            <Form key={activePage.id}>
              <TextAreaField
                value={record[activePage.id]}
                onValueChange={(value) => {
                  handleValueChanged(activePage, value);
                }}
                onValidityChange={(isValid) => {
                  handleValidityChanged(activePage, isValid);
                }}
                required
              />
            </Form>
          ),
        };
      case "vanskelig_tilgjengelig":
        return {
          title: activePage.title,
          content: (
            <Form key={activePage.id}>
              <StedTilgjengelighetField
                record={record}
                onValueChange={handleValueChanged}
                onValidityChange={handleValidityChanged}
              />
            </Form>
          ),
        };
      case "lagre":
        return {
          title: activePage.title,
          content: <Summary record={record} pages={pages} />,
          isValid: true,
          submitText: "Send inn",
          onSubmit: () => handleSave(),
        };
      case "takk":
        return {
          title: "Takk for ditt bidrag!",
          content: <img src={takkImg} alt="" style={{ maxWidth: 300 }} />,
          isValid: true,
          submitText: "Vil du si noe mer?",
        };
      default:
        return {
          title: activePage.title,
          content: <div>{activePage.id} - ikke implementert</div>,
          isValid: true,
        };
    }
  }, [
    activePage,
    record,
    handleSave,
    handleValueChanged,
    handleValidityChanged,
  ]);

  const handleCloseHelp = useCallback(() => {
    setShowHelp(false);
  }, []);

  const handleShowHelp = useCallback(() => {
    setShowHelp(true);
  }, []);

  return (
    <div className="app">
      {showHelp && <Help onClose={handleCloseHelp} />}
      <Page
        nextPage={nextPage}
        previousPage={previousPage}
        isFirstPage={activePageIdx === 0}
        isLastPage={activePageIdx === pages.length - 1}
        nextEnabled={!saving && (isValid || recordValid[activePage.id])}
        title={title}
        subtitle={subtitle}
        theme={theme}
        hideHeader={hideHeader}
        submitText={submitText}
        onSubmit={onSubmit}
        onShowHelp={handleShowHelp}
      >
        {content}
      </Page>
    </div>
  );
}

export default App;
