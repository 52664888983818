import React, { useCallback, useEffect, useState } from "react";
import "./TextAreaField.scss";
export default function TextAreaField({
  labelText,
  value,
  onValueChange,
  onValidityChange,
  required,
}) {
  const [valid, setValid] = useState(true);

  console.log("textarea - value", value);

  useEffect(() => {
    const invalidRequired = required && !value;

    const isValid = !invalidRequired;
    if (isValid !== valid) {
      setValid(isValid);
      onValidityChange && onValidityChange(isValid);
    }
  }, [value, valid, required, onValidityChange]);

  const handleChange = useCallback(
    (e) => {
      onValueChange(e.target.value);
    },
    [onValueChange]
  );

  return (
    <div className="textarea-field">
      <label>{labelText}</label>
      <textarea value={value || ""} onChange={handleChange} />
    </div>
  );
}
