import React from 'react';
import PropTypes from 'prop-types';
import './Icon.scss';

const icons = {
  missing: (
    <svg viewBox="0 0 10 10">
      <g fill="red">
        <rect width={10} height={10} />
      </g>
    </svg>
  ),
  chevron: (
    <svg viewBox="0 0 24 24">
      <g stroke="currentColor" strokeWidth="3" fill="none">
        <path d="M8,4L17,12L8,20" />
      </g>
    </svg>
  ),
  arrow: (
    <svg viewBox="0 0 24 24">
      <g stroke="currentColor" strokeWidth="2.5" fill="none">
        <path d="M11,5L19,12L11,19" />
        <path d="M19,12L4,12" />
      </g>
    </svg>
  ),
  download: (
    <svg viewBox="0 0 24 24">
      <g stroke="currentColor" strokeWidth="2.5" fill="none">
        <path d="M7,9L12,15L17,9" />
        <path d="M12,14L12,4" />
        <path d="M4,16L4,20,L20,20L20,16" />
      </g>
    </svg>
  ),

  checkCircle: (
    <svg viewBox="0 0 24 24">
      <g fill="currentColor">
        <path d="M2,12a10,10 0 1,1 20,0a10,10 0 1,1 -20,0ZM15.5,7l-4,6l-3,-3l-2,2l4,5l2,0l5.35,-8.175Z" />
      </g>
    </svg>
  ),

  blank: <svg viewBox="0 0 10 10" />
};

const Icon = ({ name, rotate, title, className = null }) => (
  <span className={['icon', rotate ? 'r' + rotate : '', className || ''].join(' ')} title={title}>
    {icons[name] || icons.missing}
  </span>
);

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  rotate: PropTypes.oneOf(['90', '180', '270', 90, 180, 270]),
  title: PropTypes.string,
  className: PropTypes.string
};

export const IconList = () => {
  return (
    <div className="icon-list">
      {Object.keys(icons)
        .sort()
        .filter((name) => name !== 'missing')
        .map((name) => (
          <div key={name}>
            <Icon name={name} /> {name}
          </div>
        ))}
    </div>
  );
};

export default Icon;
