import { useMemo } from "react";
import { getImageUrl } from "../Images";
import "./Summary.scss";

const QuestionAndAnswer = ({ question, children }) => (
  <div className="question-answer">
    {question && <label>{question}</label>}
    {children}
  </div>
);
const Answer = ({ answer }) => (
  <div className="answer">
    <span>{answer}</span>
  </div>
);
const AnswerWithImage = ({ questionKey, answer, hideAnswer }) => {
  return (
    <div className="answer">
      {questionKey && (
        <img src={getImageUrl(questionKey, answer)} alt={answer} />
      )}
      {!hideAnswer && <span>{answer}</span>}
    </div>
  );
};

export default function Summary({ record, pages }) {
  const summary = useMemo(() => {
    var mapped = Object.keys(record).map((key) => {
      const p = pages.find((page) => page.id === key);
      const hasQuestion = p && p.title;
      if (!hasQuestion || key === "geom_wkt") {
        return null;
      }

      const hideAnswer = key === "sted_rangering";
      let answer;
      if (p && p.hasImage) {
        answer = (
          <AnswerWithImage
            questionKey={key}
            question={p && p.title}
            answer={record[key]}
            hideAnswer={hideAnswer}
          />
        );
      } else {
        let answerFromRecord = record[key];
        if (key === "vanskelig_tilgjengelig" && answerFromRecord === "Ja") {
          if (record["vanskelig_tilgjengelig_kommentar"]) {
            answerFromRecord +=
              " - " + record["vanskelig_tilgjengelig_kommentar"];
          }
        }
        answer = <Answer question={p && p.title} answer={answerFromRecord} />;
      }

      return (
        <QuestionAndAnswer question={p && p.title}>{answer}</QuestionAndAnswer>
      );
    });
    return mapped;
  }, [record, pages]);
  return <div className="summary">{summary}</div>;
}
