const config = () => {
  const hostname = window && window.location && window.location.hostname;

  switch (hostname) {
    case "stedsbruk.no":
      return {
        adaptiveUrl: "https://prosjekt.avinet.no/",
        themeUuid: "9a692fd8-40be-4926-956b-b81e8371d65b",
      };
    default:
      return {
        adaptiveUrl: "https://a3latest.avinet.no/",
        themeUuid: "860106dc-0f3d-4150-832e-c668bdd82846",
      };
  }
};

export default config;
