import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { olUtils, MapContext } from "@avinet/react-openlayers";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";

export default function useGeometryPicker({
  value,
  onValueChange,
  onValidityChange,
  required = false,
  autoZoom = false,
  useLocationAsValue = false,
}) {
  const { setCenterAndZoom } = useContext(MapContext);

  const [valid, setValid] = useState(true);
  const [, setZoomedTo] = useState();

  useEffect(() => {
    const invalidRequired = required && !value;

    const isValid = !invalidRequired;
    if (isValid !== valid) {
      setValid(isValid);
      onValidityChange && onValidityChange(isValid);
    }
  }, [value, valid, required, onValidityChange]);

  const onChange = useCallback(
    (feature) => {
      const geom = feature.getGeometry().clone();
      const wkt = olUtils.getWktFromFeature(new Feature(geom));
      onValueChange(wkt || null);
    },
    [onValueChange]
  );

  const onLocation = useCallback(
    (coords) => {
      const feature = new Feature(new Point(coords));

      feature.getGeometry().transform("EPSG:4326", "EPSG:3857");
      const wkt = olUtils.getWktFromFeature(feature);
      if (useLocationAsValue) {
        onValueChange(wkt || null);
      }
      setCenterAndZoom(feature.getGeometry().getCoordinates(), 14);
    },
    [onValueChange, setCenterAndZoom, useLocationAsValue]
  );

  const feature = useMemo(() => {
    if (!value) return;
    try {
      const feature = olUtils.createFeatureFromWkt(value);
      return feature;
    } catch {}
  }, [value]);

  useEffect(() => {
    if (autoZoom && value) {
      setZoomedTo((z) => {
        if (z !== value) {
          // TODO Support more geometry types by using extent for non-point geoms
          const feature = olUtils.createFeatureFromWkt(value);
          setCenterAndZoom(feature.getGeometry().getCoordinates(), 14);
        }
        return value;
      });
    }
  }, [autoZoom, value, setCenterAndZoom]);

  return { feature, onChange, onLocation };
}
