import React, { useMemo } from "react";
import "./SubmitBtn.scss";

export default function SubmitBtn({ disabled, onClick, text }) {
  const className = useMemo(() => {
    let classes = ["submit-btn"];
    disabled && classes.push("disabled");
    return classes.join(" ");
  }, [disabled]);
  return (
    <button className={className} disabled={disabled} onClick={onClick}>
      {text}
    </button>
  );
}
