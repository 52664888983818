import React from "react";
import Icon from "./Icon";
import SubmitBtn from "./SubmitBtn";
import "./Page.scss";

const Content = ({ children }) => <div className="content">{children}</div>;

export default function Page({
  nextPage,
  previousPage,
  isFirstPage,
  isLastPage,
  children,
  hideHeader,
  theme,
  nextEnabled,
  title,
  subtitle,
  submitText,
  onSubmit,
  onShowHelp,
}) {
  return (
    <div className={"page" + (theme ? " " + theme : "")}>
      {!hideHeader && (
        <header>
          <Content>
            {!isFirstPage && (
              <button
                className="back-btn"
                onClick={() => (isLastPage ? nextPage(0) : previousPage())}
              >
                <Icon name="chevron" rotate={180} /> {"Tilbake"}
              </button>
            )}
            <button className="help-btn" onClick={onShowHelp}>
              Hjelp
            </button>
            <h2>{title}</h2>
            {subtitle && <h3>{subtitle}</h3>}
          </Content>
        </header>
      )}
      <section>
        <Content>
          {hideHeader && (
            <button className="help-btn" onClick={onShowHelp}>
              Om løsningen
            </button>
          )}
          {children}
        </Content>
      </section>
      <footer>
        <Content>
          <SubmitBtn
            disabled={!nextEnabled}
            onClick={() =>
              onSubmit ? onSubmit() : nextPage(isLastPage ? 0 : null)
            }
            text={submitText || "Neste"}
          />
        </Content>
      </footer>
    </div>
  );
}
