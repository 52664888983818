import React from "react";
import { VectorSource, Draw, defaultStyle } from "@avinet/react-openlayers";
import ZoomToPosition from "./ZoomToPosition";
import useGeometryPicker from "../hooks/useGeometryPicker";
import "./PointGeometryPicker.scss";

const style = defaultStyle({ pointRadius: 7, fillColor: "#002e4c" });

function PointGeometryPicker({
  value,
  onValueChange,
  onValidityChange,
  required,
  autoZoom,
  readOnly,
  disabled,
  mapProjection,
  dataSourceProjection,
}) {
  const { feature, onLocation, onChange } = useGeometryPicker({
    value,
    onValueChange,
    onValidityChange,
    required,
    autoZoom,
    useLocationAsValue: true,
    mapProjection,
    dataSourceProjection,
  });

  return (
    <div className="point-geometry-picker">
      <ZoomToPosition onLocation={onLocation} title={"Bruk min posisjon"} />
      <VectorSource
        layerName="point-geometry-picker"
        selectable={true}
        features={(feature && [feature]) || []}
        style={style}
        zIndex={1}
      >
        {!readOnly && !disabled && <Draw type="Point" onDrawEnd={onChange} />}
      </VectorSource>
    </div>
  );
}

export default PointGeometryPicker;
