import React, { useCallback, useState } from "react";
import { LoadingMask } from "./Loading";
import "./ZoomToPosition.scss";

const ZoomToPosition = ({ onLocation, title }) => {
  const [loading, setLoading] = useState(false);

  const onZoomToPosition = useCallback(() => {
    setLoading(true);
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setLoading(false);
        onLocation([position.coords.longitude, position.coords.latitude]);
      },
      (err) => {
        console.error(err);
        setLoading(false);
      }
    );
  }, [onLocation]);

  return (
    <button
      type="button"
      className="zoom-to-position"
      onClick={onZoomToPosition}
      disabled={loading}
    >
      <svg viewBox="0 0 26 26">
        <path fill="currentColor" d="M1,11l10,3l3,10l10 -23Z" />
      </svg>
      <span>{title}</span>
      {loading && <LoadingMask />}
    </button>
  );
};

export default ZoomToPosition;
