import React, { useMemo } from "react";
import OptionsField from "./OptionsField";
import TextAreaField from "./TextAreaField";

const yesNoItems = ["Ja", "Nei"];
export default function StedTilgjengelighetField({
  record,
  onValueChange,
  onValidityChange,
  required,
}) {
  const showComment = useMemo(() => record.vanskelig_tilgjengelig === "Ja", [
    record.vanskelig_tilgjengelig,
  ]);
  return (
    <div>
      <OptionsField
        name="vanskelig_tilgjengelig"
        value={record.vanskelig_tilgjengelig}
        onValueChange={(value) => {
          onValueChange("vanskelig_tilgjengelig", value);
        }}
        onValidityChange={(isValid) => {
          onValidityChange("vanskelig_tilgjengelig", isValid);
        }}
        items={yesNoItems}
        required
      />
      {showComment && (
        <TextAreaField
          value={record.vanskelig_tilgjengelig_kommentar}
          onValueChange={(value) => {
            onValueChange("vanskelig_tilgjengelig_kommentar", value);
          }}
          onValidityChange={(isValid) => {
            onValidityChange("vanskelig_tilgjengelig_kommentar", isValid);
          }}
          labelText="Beskriv hvorfor"
        />
      )}
    </div>
  );
}
