import React from "react";
import "./Loading.scss";

export function LoadingDots() {
  return (
    <div className="loading-dots">
      <span />
      <span />
      <span />
      <span />
      <span />
    </div>
  );
}

export function LoadingMask() {
  return <div className="loading-mask"></div>;
}

export default function Loading({ text = "Laster..." }) {
  return (
    <div className="loading">
      <LoadingDots />
      {text}
    </div>
  );
}
