import "./Help.scss";
export default function Help({ onClose }) {
  return (
    <>
      <div className="modal-overlay" />
      <div className="help-modal">
        <button className="close-btn" onClick={onClose}>
          Lukk
        </button>
        <div className="content">
          <h2>Om Stedsbruk.no</h2>
          Stedsbruk.no er et digitalt medvirkningsverktøy for å kartlegge folks
          stedsbruk og stedsopplevelser. Nettsiden blir benyttet i konkrete
          plan- eller medvirkningsprosesser, for å gi tilbakemelding til
          beslutningstakere i din kommune om hva innbyggerne mener om ulike
          steder i kommunen. Hvor er de gode stedene, hvor er det ikke så bra og
          hva bør gjøres her?
          <p>Utviklet av Asplan Viak</p>
          <p>Ikoner og illustrasjoner ved Amonrat Rungreangfangsai</p>
        </div>
      </div>
    </>
  );
}
