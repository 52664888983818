import maleImg from "./public/mann.png";
import femaleImg from "./public/kvinne.png";

import shoppingImg from "./public/shopping.svg";
import grontImg from "./public/gront.svg";
import lekeplassImg from "./public/lekeplass.svg";
import trikkImg from "./public/trikk.svg";
import boligImg from "./public/bolig.svg";

import sintImg from "./public/sint.svg";
import mindreSintImg from "./public/mindre_sint.svg";
import noytralImg from "./public/noytral.svg";
import godImg from "./public/god.svg";
import veldigGod from "./public/veldig_god.svg";

import arbeidImg from "./public/arbeid.svg";
import besokImg from "./public/besok.svg";
import innbyggerImg from "./public/innbygger.svg";

const imageMapping = {
  kjonn: { Mann: maleImg, Kvinne: femaleImg },
  sted_rangering: {
    1: sintImg,
    2: mindreSintImg,
    3: noytralImg,
    4: godImg,
    5: veldigGod,
  },
  sted_type: {
    "Handel og kultur": shoppingImg,
    Grøntområde: grontImg,
    "Sport, lek og fritid": lekeplassImg,
    "Gate, veg og kollektiv": trikkImg,
    Boligområde: boligImg,
  },
  tilhorighet: {
    Innbygger: innbyggerImg,
    "Jobber her": arbeidImg,
    "På besøk": besokImg,
  },
};

const getImageUrl = (key, imageId) => {
  return imageMapping[key] && imageMapping[key][imageId];
};

export { getImageUrl };
