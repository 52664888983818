import {
  ImageWmsLayer,
  Map,
  MapContext,
  TileWmsLayer,
  WmtsLayer,
  Zoom,
} from "@avinet/react-openlayers";
import React, { useContext, useEffect, useMemo } from "react";
import Projection from "ol/proj/Projection";

const defaultOptions = {
  layers: [
    {
      type: "wms",
      id: "norges_grunnkart",
      name: "Norgeskart",
      uri: "https://opencache.statkart.no/gatekeeper/gk/gk.open",
      layers: "topo4graatone",
      version: "1.1.1",
      attributions: "&copy; Kartverket",
    },
  ],
  initialExtent: [544000, 7867000, 3623000, 11600000],
  projection: "EPSG:3857",
  projDefs: [],
};

const Layers = ({ layers }) => {
  return layers.map(({ type, ...layer }) => {
    switch (type) {
      case "wms":
        return <TileWmsLayer key={layer.id} {...layer} />;
      case "imagewms":
        return <ImageWmsLayer key={layer.id} {...layer} />;
      case "wmts":
        return <WmtsLayer key={layer.id} {...layer} />;
      default:
        console.error(`Unknown layer type '${type}'`);
        return null;
    }
  });
};

const ZoomToExtent = ({ extent }) => {
  const { fitViewAndZoom } = useContext(MapContext);
  useEffect(() => {
    fitViewAndZoom(extent);
  }, [fitViewAndZoom, extent]);
  return null;
};

const GeometryField = ({ children, options }) => {
  const { initialExtent, layers, projection, projDefs } = useMemo(
    () => ({
      ...defaultOptions,
      ...options,
    }),
    [options]
  );

  const initedProjection = useMemo(() => {
    if (typeof projection === "string") return projection;
    return new Projection(projection);
  }, [projection]);

  return (
    <Map
      minZoom={4}
      maxZoom={18}
      projection={initedProjection}
      projDefs={projDefs}
    >
      <Layers layers={layers} />
      <ZoomToExtent extent={initialExtent} />
      {children}
      <Zoom zoomInTipText="Zoom inn" zoomOutTipText="Zoom ut" />
    </Map>
  );
};

export default GeometryField;
